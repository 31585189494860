@import 'styles/global.scss';

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .spinner {
        width: 80px;
        height: 80px;
        border: 4px solid $dark-blue;
        border-radius: 100%;
        animation: basic 1s infinite linear;
        border-top-color: $blue;
    }

    &.button {
        .spinner {
            width: 20px;
            height: 20px;
            border-color: #fff;
            border-top-color: transparent;
            border-width: 2px;
        }
    }


    &.small {
        .spinner {
            width: 15px;
            height: 15px;
            border-width: 2px;

        }
    }

    &.select {
        position: absolute;
        right: 9px;
        justify-content: flex-end;

        .spinner {
            width: 24px;
            height: 24px;
            border-color: #909ca8;
            border-top-color: #c3c7d1;
        }
    }

    &.list {
        .spinner {
            width: 18px;
            height: 18px;
            border-color: #909ca8;
            border-top-color: #c3c7d1;
            border-width: 2px;
        }
    }

    &.order-loader {
        .spinner {
            width: 60px;
            height: 60px;
            border-color: #aab8c4;
            border-top-color: #d0d4de;
            border-width: 3px;
        }
    }
}

@keyframes basic {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359.9deg);
    }
}
