@media screen and (max-width: $media-limit-mobile) {
    html {
        width: auto;
        overflow-x: auto;
    }

    html,
    body {
        min-width: auto;
    }

    .container {
        padding-top: 65px;
        .mobile-popup {
            height: calc(100% - 65px);
        }

        &.dev {
            padding-top: 108px;

            .mobile-popup {
                height: calc(100% - 108px);
            }
        }

        .content {
            position: relative;

            .mobile-helper {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    #app .env {
        position: fixed;
        background: white;
        z-index: 1000;
    }

    .wrapper {
        padding: 0 10px;

        & > div {
            width: 100%;
        }
    }

    .media-desktop {
        display: none;
    }

    .media-mobile {
        display: block;
    }

    .page-title {
        font-size: 24px;
        padding: 30px 0 15px 0;
    }

    .page {
        & > div {
            padding-bottom: 30px;
        }
        & > .loader {
            padding-bottom: 0;
        }

        .tabs {
            position: relative;

            .tab {
                width: auto;
                height: 48px;
                padding: 0 10px;
                line-height: 48px;
                font-size: 18px;
                text-align: center;
                flex-shrink: 0;
                flex-grow: 1;
            }
        }

        .wrap {
            padding: 20px 15px;

            .section {
                .header {
                    padding-bottom: 25px;

                    .title {
                        font-size: 20px;
                        display: flex;
                        flex-direction: column;

                        .subtitle {
                            margin-left: 0;
                            padding-top: 10px;
                        }
                    }
                }
            }

            .footer {
                .btns {
                    button {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
