@import 'styles/global.scss';

button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 48px;
    padding: 0 20px;
    margin: 0;
    font-family: 'UniNeue', sans-serif;
    line-height: 48px;
    border-radius: 3px;
    overflow: hidden;
    transition: all 0.2s linear;
    background: #fff;
    border: none;
    text-align: center;

    &:disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &:focus:not(:disabled) {
        color: #254566;
    }

    span {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
    }

    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }

    &.bordered {
        height: 48px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: bold;
        border: solid 1px #e2e7ed;
        line-height: 46px;
        color: $dark-blue;

        &:hover {
            border-color: #ced6de;
            color: #557ca2;
        }

        &:active {
            border-color: #bcc9d7 !important;
            color: #254566 !important;
        }
    }

    &.gradient {
        padding: 0 30px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.2px;
        color: #fff;

        &:before,
        &:after,
        .bg-pressed {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: calc(100% + 1px);
            height: 100%;
            content: '';
            background-image: linear-gradient(98deg, #78b9ff, #56a0ef 59%, #519cec);
            transition: all 0.2s linear;
        }

        &:after {
            opacity: 0;
            background-image: linear-gradient(98deg, #8bc3ff, #5da4f0);
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        &:active {
            .bg-pressed {
                opacity: 1 !important;
            }
        }

        .bg-pressed {
            opacity: 0;
            background-image: linear-gradient(98deg, #5ca3ee, #2f7aca);
        }
    }

    &.plain {
        padding: 0 20px;
        font-size: 16px;
        color: $dark-blue;
        background: transparent;

        .loader .spinner {
            border-top-color: $blue;
        }

        &:hover {
            background-color: $silver;
        }

        &:active {
            background-color: #e4e8f1 !important;
        }

        &.large {
            font-size: 18px;
        }

        &:disabled {
            background: #f7f8fa;
            color: $grey;
            border: 1px solid #f5f5f5;
        }
    }

    &.blue {
        background: $blue;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;

        &:hover {
            background-color: #5ea7f3;
        }

        &:active,
        &:focus:not(:disabled) {
            background-color: $blue-pressed !important;
            color: white;
        }

        &:disabled {
            background-color: #cccccc;
        }
    }

    &.red {
        background: #df4545;
        font-size: 18px;
        font-weight: bold;
        color: #fff;

        &:hover {
            background-color: #f35e5e;
        }

        &:active,
        &:focus:not(:disabled) {
            background-color: #df4545 !important;
            color: white;
        }
    }
    &.remove {
        background: $error;
        font-size: 18px;
        font-weight: bold;
        color: #fff;

        &:hover {
            color: white;
            background-color: $red;
        }

        &:active,
        &:focus:not(:disabled) {
            background-color: $error-pressed !important;
            color: white;
        }
    }

    &.large {
        height: 56px;
        padding: 0 40px;
        line-height: 56px;
    }

    &.bold {
        font-weight: bold;
    }

    .loader {
        position: absolute;
        top: 0;
    }

    &.loading {
        pointer-events: none;

        .slot {
            opacity: 0;
        }
    }

    &.full {
        width: 100%;
    }

    &.small {
        height: 34px;
        padding: 0 16px;
        line-height: 32px;
        font-size: 14px;
    }

    &.inverse {
        border: 1px solid $blue;
        color: $blue;
        background-color: $white;
        transition: all 0.2s;

        &:hover {
            background-color: $blue;
            color: $white;
        }

        &:active,
        &:focus:not(:disabled) {
            background-color: #4590df;
            border: 1px solid #4590df;
            color: white;
        }
    }

    &.medium {
        height: 40px;
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
    }

    &.gray {
        background-color: #f1f4fa;
        color: $dark-blue;

        &:hover,
        &:focus:not(:disabled) {
            background-color: #dce2ee;
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    button {
        &.large {
            height: 48px;
            padding: 0 30px;
            line-height: 48px;
        }
    }
}
