@import "@/admin/assets/scss/global";

.a-data-card {
  padding: 4px 20px 20px 20px;
  font-family: 'Avenir Next', sans-serif;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.05);
  overflow-x: auto;
  &__title {
    color: $dark-blue;
  }

  &__records {
    display: flex;
    flex-direction: column;
  }

  &__record {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0,0,0,0.05);
    }

  }
  &__s-title {
    font-weight: 600;
    font-size: 14px;
    color: $dark-old;

  }
  &__value {
    font-weight: 600;
    font-size: 14px;
    max-width: 300px;
    text-align: right;
    white-space: pre-line;
  }
  &__subValue {
    font-weight: 500;
    font-size: 11px;
    color: $grey;
    max-width: 300px;
    text-align: right;
    white-space: pre-line;
  }
  &__divider {
    width: 100%;
    height: 2px;
    background: $main;
    margin: -2px 0 4px;
  }
  .css-14mup8f-RaDatagrid-root .RaDatagrid-table.fixed-layout {
    table-layout: fixed;
  }
}
