.tos-datacard {
  .a-data-card__record:first-child {
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  .tos-accept-history {
    display: flex;
    flex-direction: column;
    span {
      padding: 2px;
      font-size: 13px;
      color: black;
      font-weight: 500;
    }
  }
}
