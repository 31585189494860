$main: #5c6064;
$grey: #979ea6;
$silver: #f1f4fa;
$light-silver: #f7f8fa;
$red: #fa837a;
$white: #fff;
$black: #000;
$light-blue: #e0f0ff;
$blue: #519cec;
$blue-pressed: #4590df;
$dark-old: #51585f;
$dark: #000;
$orange: #e09c3c;
$yellow: #e0ca3c;
$grey-blue: #9caec5;
$dark-blue: #416283;
$medium-blue: #48617f;
$error: #ed6868;
$error-pressed: #da5858;
$green: #7cce66;
$success: #52a775;
$border-color: #e5e9f1;

$media-limit-mobile-sm: 768px;
$media-limit-mobile: 1023px;

// Order statuses
$order-pending: #ee8300;

// Entity statuses
$entity-active: #29ad4c;
$entity-rejected: #ff5c6f;
$entity-banned: #ee8300;
$entity-inactive: rgba(65, 98, 131, 0.7);

@mixin placeholder() {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}
