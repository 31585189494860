.a-order-view {
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
    padding: 4px 4px 24px;
  }
  &__heading {
  display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__container {

  }
  &__title {

  }
}
