.admin-status-dot {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;

  &:before {
    display: block;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 50%;
  }

  &__error:before {
    background-color: #ff5656;
    box-shadow: 0 0 4px 0 #ff5656;
  }

  &__success:before {
    background-color: #57e08c;
    box-shadow: 0 0 4px 0 #57e08c;
  }

  &__warning:before {
    background-color: #f5d922;
    box-shadow: 0 0 4px 0 #f5d922;
  }
}
