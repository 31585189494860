@import 'styles/global.scss';
.warehouse-exports {
  padding: 0 20px 20px 20px;
  margin-top: 20px;
    .warehouse-exports__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
  .available-exports {
    margin-top: 20px;
    display: flex;

    &__item {
      margin-bottom: 10px;
      padding: 30px 20px;
      width: 270px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
        border-radius: 6px;
      box-shadow: 0 0 4px 1px rgba(0,0,0,0.05);
      &:not(:last-child) {
        margin-right: 20px;
      }

      &__title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &__description {
        font-size: 14px;
        color: #9b9b9b;
        flex: 1;
      }
      &__button {
        border-top: 1px solid #ebebeb;
        margin-top: 12px;
        width: 100%;
        display: flex;
        justify-content: center;

        button {
          margin-top: 24px;
          max-width: 150px;
            width: 100%;
          font-weight: 600;
          font-size: 12px;
            height: 32px;
            border-radius: 4px;
            background: white;
          color: $blue;
            border: 1px solid rgba(235, 235, 235, 0.7);
          box-shadow: 0 0 8px 1px rgba(0,0,0,0.05);
            padding: 4px 12px;
            margin-right: 8px;
            cursor: pointer;
          &:hover:not(:disabled) {
            background: $blue;
            color: white;
          }
            &:focus {
                outline: none;
            }
        }
      }
    }
  }
}
