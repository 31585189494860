@import "styles/global.scss";
.modal-info {
    .message {
        font-weight: 500;
        letter-spacing: -0.2px;
        color: #979ea6;
        padding: 10px 0 10px 0;
        font-size: 18px;
    }


    .btns {
        display: flex;
        justify-content: flex-end;

        button {
            width: 120px;
            margin-left: 20px;
        }
    }
}

.modal-container .modal-info.err {
     .modal-header {
        color: $error;
    }
}
