.UnabledeleteAccountModal {
    &__title {
        margin-top: 0;
    }
    &__btns-wrapper {
        display: flex;
        justify-content: flex-end;
    }
    &__btn-cancel {
        margin-right: 12px;
    }
}