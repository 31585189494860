@import 'styles/global.scss';
.admin-app {
  overflow: auto;

  .MuiButton-sizeSmall {
    height: 48px;
  }

  .long-field {
    position: relative;
    width: 250px;
    line-height: 1;
    /* border: 1px solid; */
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .boolean-status-field {
    position: relative;
    display: inline-block;
    padding: 2px 5px;

  }

  .MuiTypography-root.MuiTypography-body2 {
    font-size: 13px;
  }
  .RaDatagrid-headerCell {
    font-weight: 600;
    font-family: 'UniNeue';
  }

  &__sidebar {
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 300px;
    min-width: 300px;
    padding: 12px 12px 20px 12px;

    h3 {
      text-transform: uppercase;
      margin-bottom: 4px;
      cursor: pointer;
    }

    span {
      color: black;
      font-size: 10px;
    }
  }

  &__menu {
    margin-top: 12px;
    border-top: 1px solid $silver;
    padding: 12px 0;
  }

  &__menu-link {
    padding: 4px 12px;
    color: $blue;
    width: 100%;
    display: flex;

    &:hover {
      color: $blue-pressed;
    }

    &.active {
      font-weight: 600;
    }

    &.logout {
      color: $grey;
      cursor: pointer;
      margin-top: 12px;
      font-size: 12px;
      padding-top: 12px;
      border-top: 1px solid $silver;
    }
  }

  &__container {
    overflow-y: auto;
    padding: 20px;
    position: relative;
  }

  .MuiSnackbarContent-message {
    text-align: center;
    width: 100%;
  }
  .list-page {
    width: 100%;
  }
}


.RaAppBar-menuButton {
  svg {
    color: white;
  }
}

.ml10 {
  margin-left: 10px;
}




