@font-face {
    font-family: 'UniNeue';
    src: url('../fonts/UniNeueLight.woff2') format('woff2'), url('../fonts/UniNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'UniNeue';
    src: url('../fonts/UniNeueRegular.woff2') format('woff2'), url('../fonts/UniNeueRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'UniNeue';
    src: url('../fonts/UniNeueBold.woff2') format('woff2'), url('../fonts/UniNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Light.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNext', sans-serif;
    src: url('../fonts/AvenirNextCyr-Italic.woff2') format('woff2'),
        url('../fonts/AvenirNextCyr-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

.icon-plus {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #7cce66;
    border-radius: 50%;
    margin-right: 6px;
    margin-top: 1px;
    color: #fff;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 8px;
        height: 2px;
        content: '';
        background-color: #fff;
        border-radius: 1px;
        transform: translate(-50%, -50%);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.icon-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
        width: 4px;
        height: 4px;
        margin-top: 2px;
        background: $dark-blue;
        border-radius: 50%;

        &:first-child {
            margin-top: 0;
        }
    }
}

.icon-triangle {
    width: 10px;
    height: 5px;

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $dark-old;
    }

    &.reverse {
        &:after {
            transform: rotate(180deg);
        }
    }
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        transition: all 0.2s linear;
    }
}

.icon-calendar:before {
    content: '\e900';
}

.icon-arrow:before {
    content: '\e901';
}

.icon-basket:before {
    content: '\e902';
}

.icon-print:before {
    content: '\e903';
}

.icon-arrow-round:before {
    content: '\e904';
}

.icon-add:before {
    content: '\e905';
}

.icon-star:before {
    content: '\e906';
}

.icon-close:before {
    content: '\e907';
}

.icon-crossdocking:before {
    content: '\e908';
}

.icon-filter:before {
    content: '\e909';
}

.icon-freeze:before {
    content: '\e90a';
}

.icon-freeze-local:before {
    content: '\e90b';
}

.icon-license:before {
    content: '\e90c';
}

.icon-mail:before {
    content: '\e90d';
}

.icon-user:before {
    content: '\e90e';
}

.icon-pallets:before {
    content: '\e90f';
}

.icon-phone:before {
    content: '\e910';
}

.icon-local-destribution:before {
    content: '\e911';
}

.icon-shrink-wrapping:before {
    content: '\e90f';
}

.icon-pin:before {
    content: '\e912';
}

.icon-play:before {
    content: '\e913';
}

.icon-arrow-long:before {
    content: '\e915';
}

.icon-search:before {
    content: '\e916';
}

.icon-logo-dark:before {
    content: '\e917';
}

.icon-sandwich:before {
    content: '\e918';
}

.icon-checkbox:before {
    content: '\e914';
}

.icon-download:before {
    content: '\e91a';
}

.icon-arrow-right:before {
    content: '\e91b';
}

.icon-pin-small:before {
    content: '\e91c';
}

.icon-text-search:before {
    content: '\e91d';
}

.icon-arrow-thin:before {
    content: '\e91e';
}

.icon-status {
    display: inline-block;
}

.icon-status.pending:before {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #ef8473;
    border-radius: 5px;
    content: '';
}

.icon-status.sub-order:before {
    display: block;
    width: 20px;
    height: 20px;
    content: '';
    background: url('../images/sub-order.svg') no-repeat;
    background-size: cover;
}

.icon-eye {
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 24px;
    height: 18px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    overflow: hidden;
    box-sizing: border-box;
}

.icon-eye::after,
.icon-eye::before {
    content: '';
    display: block;
    border-radius: 100px;
    position: absolute;
    box-sizing: border-box;
}

.icon-eye::after {
    top: 2px;
    box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
    width: 24px;
    height: 24px;
}

.icon-eye::before {
    width: 8px;
    height: 8px;
    border: 2px solid;
    bottom: 4px;
    left: 8px;
}

.icon-eye:hover {
    &::after,
    &::before {
        content: '';
        display: block;
        border-radius: 100px;
        position: absolute;
        box-sizing: border-box;
    }

    &::after {
        top: 2px;
        box-shadow: inset 0 -8px 0 2px, inset 0 0 0 2px;
        width: 24px;
        height: 24px;
    }

    &::before {
        width: 8px;
        height: 8px;
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 6px, 0 0 0 4px, 6px 0 0 0, -6px 0 0 0;
        bottom: 4px;
        left: 8px;
    }
}
