.modal-editor {
    .message {
        font-weight: 500;
        letter-spacing: -0.2px;
        color: #979ea6;
        padding: 20px 0 40px 0;
    }

    .btns {
        display: flex;
        justify-content: flex-end;

        button {
            width: 120px;
            margin-left: 20px;
        }
    }
    .css-bhp9pd-MuiPaper-root-MuiCard-root {
        box-shadow: none;
    }
}
.admin-app .MuiSnackbarContent-message {
    text-align: center;
    width: 100%;
}

