@import '@/assets/scss/global.scss';
@import '@/assets/scss/normalize.scss';
@import '@/assets/scss/common.scss';
@import '@/assets/scss/page.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media.scss';
@import '@/assets/scss/form.scss';

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 8px;
    background: rgba(246, 246, 246, 0.55);
}
.simplebar-scrollbar:before {
    background: #7a7a7a;
    border-radius: 0;
}

.modal-content {
    .tabs {
        margin-bottom: 16px;
    }

    .btns {
        margin-top: 24px;
    }
}
