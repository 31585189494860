@import 'styles/global.scss';

.a-login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .app-version {
    padding: 12px 20px;
    width: 324px;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        span:last-child {
          font-weight: bold;
        }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $silver;
    border-radius: 4px;
    background: white;

    width: 324px;

    img {
      margin: 12px auto;
    }

  }
}
