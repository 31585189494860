@import 'styles/global.scss';

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.modal-container {
    .modal-box {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        border-radius: 8px;
        padding: 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);

        &.small {
            width: 330px;
            padding: 24px 16px;
        }

        &.medium {
            width: 530px;
        }

        &.large {
            width: 800px;
        }

        &.huge {
            width: 900px;
            max-width: 900px;
        }

        .modal-header {
            font-family: UniNeue;
            font-size: 28px;
            font-weight: 700;
            letter-spacing: -0.3px;
            color: #416283;
            padding-right: 20px;
            padding-bottom: 20px;
            text-align: left;
        }

        .scrollbar-padding {
            overflow: auto;
            padding: 0 12px 0 0;
        }

        .icon-close {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-size: 12px;
            color: #979ea6;
            cursor: pointer;

            &:hover {
                color: #aeb6bf;
            }

            &:active {
                color: #757e88;
            }
        }

        .title {
            font-family: 'UniNeue';
            font-size: 28px;
            font-weight: bold;
            letter-spacing: -0.3px;
            color: $dark-blue;
            padding-right: 20px;
            padding-bottom: 20px;
            text-align: left;

            &.small {
                font-size: 14px;
                font-weight: 300;
            }
        }

        .description {
            color: #519cec;
            font-family: 'UniNeue';
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.3px;
            margin-top: -20px;
            padding-right: 20px;
            padding-bottom: 20px;
            text-align: left;

            &.small {
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
}

@media screen and (max-width: $media-limit-mobile) {
    .modal-container {
        .modal-box {
            margin: 1rem;

            &.large,
            &.medium,
            &.huge {
                max-height: calc(100% - 2rem);
                width: calc(100% - 2rem);
            }
        }
    }
}

@keyframes olimpModalIn {
    0% {
        opacity: 0;
        transform: scale(1.1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes olimpModalOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}
