.form {
    .field {
        margin-bottom: 5px;

        &-tabs {
            padding-bottom: 25px;
        }
    }
    .w-error {
        min-height: 16px;
        padding: 4px 0 0 0;
        line-height: 1;

        .error {
            font-size: 12px;
            color: #ed6868;
        }
    }

    .error {
        font-size: 11px;
        color: $error;
    }
    .footer {
        padding-top: 20px;
    }

    .vue-slider-tooltip {
        transition: margin 0.1s;
    }

    .slider {
        &.first-quarter {
            .vue-slider-tooltip {
                margin: 0 -4px 0 0;
            }
        }

        &.last-quarter {
            .vue-slider-tooltip {
                margin: 0 0 0 -14px;
            }
        }
    }
}
