.breadcrumb-block {
  margin-top: 20px;
  margin-left: 4px;
  a.disabled {
    pointer-events: none;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
  }
}

.RaShow-main .breadcrumb-block {
  margin-top: 0;
  margin-left: 0;
}
